import Api from "@/Api";

export default {
  props: {
    showNavbarProp: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  data() {
    return {
      vorschauUrl: null,
      showNavbar:
        this.$route.query.showNavbar === undefined ||
        this.$route.query.showNavbar !== "false"
    };
  },
  computed: {
    isExtern() {
      if (!this.angemeldeterMitarbeiter) return null;
      if (this.angemeldeterMitarbeiter.gruppen[0] === "extern") {
        // EXTERN
        return true;
      } else return false;
    },
    angemeldeterMitarbeiter: {
      get() {
        if (this.$store) return this.$store.state.mitarbeiter;
        return null;
      },
      set(value) {
        this.$store.commit("setMitarbeiter", value);
      }
    },
    windowHeight: {
      get() {
        this.$store.getters.windowHeight;
      },
      set(val) {
        this.$store.commit("setWindowHeight", val);
      }
    },
    windowWidth: {
      get() {
        this.$store.getters.windowWidth;
      },
      set(val) {
        this.$store.commit("setWindowWidth", val);
      }
    },
    mitarbeiterName() {
      if (this.$store) return this.$store.getters.name;
      return "";
    }
  },
  created() {
    if (!this.angemeldeterMitarbeiter)
      Api.get("angemeldeter-mitarbeiter")
        .then(response => {
          this.angemeldeterMitarbeiter = response.data;
        })
        .catch(error => {
          console.log("im catch vom post" + error.response.data);
          let login = this.$router.resolve({ name: "Login" });
          window.location.href = login.href;
        });
  },

  watch: {
    permissions(val) {
      if (val && !val.read) {
        this.$router.replace({ name: "forbidden", params: { response: {} } });
      }
    }
  },
  mounted() {
    if (!(this.showNavbarProp === null)) {
      this.showNavbar = this.showNavbarProp;
    }

    this.windowWidth = window.innerWidth;
    this.windowHeigth = window.innerHeight;

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
  }
};
